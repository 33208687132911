<template>
  <v-dialog
    id="cu-app-user-dialog"
    v-model="app_user.cuDialogAU"
    max-width="1500"
    :retain-focus="false"
    :persistent="false"
    @click:outside="closeDialog()"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        dark
        color="color_green"
        width="155"
        v-bind="attrs"
        v-on="on"
        @click="newAppUserEvent"
      >
        New App User
      </v-btn>
    </template>
    <app-user-form
      :key="parseInt(appUserForm)"
      :app-user-form="appUserForm"
      @closeDialog="closeDialog()"
      @alert="showAlert($event)"
    />
  </v-dialog>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    AppUserForm: () => import('@/components/customer/app-user/AppUserForm')
  },
  props: {
    appUserForm: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      CUDialogAU: false
    };
  },
  computed: {
    ...mapState(['app_user', 'customer'])
  },
  methods: {
    ...mapActions(['dialogAction', 'storeIsModifingAppUser', 'storeIsModifingNotCreatedAppUsers', 'storeDisable', 'storeDefaultService']),
    async newAppUserEvent() {
      await this.storeDefaultService({ customer_id: this.customer.crm_id });
      this.storeIsModifingAppUser(false);
      this.storeIsModifingNotCreatedAppUsers(false);
      this.CUDialogAU = true;
      this.storeDisable(false);
      this.dialogAction(this.CUDialogAU);
      this.emitTableItem();
      this.$emit('formAppUser');
    },
    emitTableItem() {
      this.$emit('toAppUserTable');
    },
    closeDialog() {
      this.storeIsModifingAppUser(false);
      this.storeIsModifingNotCreatedAppUsers(false);
      this.CUDialogAU = false;
      this.dialogAction(this.CUDialogAU);
    },
    showAlert(value) {
      this.$emit('showAlert', value);
    }
  }
};
</script>
